<template>
  <div>

    <div class='d-flex'>
      <v-btn-toggle dense mandatory v-model="fy" >
        <v-btn plain style="text-transform: unset;"
          v-for="fy in fys"
          :key="fy"
          :value="fy"
        >
          {{ fy }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn-toggle dense mandatory v-model="groupBy" >
        <v-btn plain style="text-transform: unset;" value="Head">Heads</v-btn>
        <v-btn plain style="text-transform: unset;" value="Tag">Tags</v-btn>
      </v-btn-toggle>
    </div>

    <Table :title="title1()"   api="/api/heads/filter" :params="apiParams1"   :class="cssClassFn()" currencyFormat="currencyInr" :rowClassFn="rowClassFn" :rowClickFn="rowClickFn1" />
    <Table :title="title2()"   api="/api/heads/filter" :params="apiParams2"   :class="cssClassFn()" currencyFormat="currencyInr" :rowClassFn="rowClassFn" :rowClickFn="rowClickFn2" />
    <Table :title="titleAcc()" api="/api/heads/filter" :params="apiParamsAcc" :class="cssClassFn()" currencyFormat="currencyInr" :rowClassFn="rowClassFn" :rowClickFn="rowClickFnAcc" />

    <Transactions v-if="apiParamsTxn.account || apiParamsTxn.head || apiParamsTxn.tag" :title="titleTxn()" api="/api/transactions" :params="apiParamsTxn" :class="cssClassFn()" />

  </div>
</template>

<script>
import axios from "axios";
import util from "@/util.js";
import Table from "../components/Table";
import Transactions from "../components/Transactions";
export default {
  components: {
    Table,
    Transactions
  },
  data() {
    return {
      type: 'Expenses',
      groupBy: 'Head',
      fy: util.currFy,
      fys: [ util.currFy, 'All' ],
      apiParams1:   { groupBy:'Head',    fy:util.currFy },
      apiParams2:   { groupBy:'Tag',     fy:util.currFy, filter: { 'head':'', 'tag':'' } },
      apiParamsAcc: { groupBy:'Account', fy:util.currFy, filter: { 'head':'', 'tag':'' } },
      apiParamsTxn: { fy:util.currFy, account:'', head:'', tag:'' },
    }
  },
  watch: {
    groupBy(valNew, valOld) {
      this.apiParams1.groupBy = valNew;
      this.apiParams2.groupBy = valOld;
      this.apiParams2.filter.head = '';
      this.apiParams2.filter.tag  = '';
      this.apiParamsAcc.filter.head = '';
      this.apiParamsAcc.filter.tag  = '';
      this.apiParamsTxn.account = '';
      this.apiParamsTxn.head    = '';
      this.apiParamsTxn.tag     = '';
    },
    fy() {
      this.apiParams1.fy = this.fy;
      this.apiParams2.fy = this.fy;
      this.apiParamsAcc.fy = this.fy;
      this.apiParamsTxn.fy = this.fy;
    },
  },
  mounted() {
    axios
      .get('/api/fys')
      .then((response) => {
        this.fys = response.data.concat([ 'All' ]);
      })
      .catch((error) => {
        util.handleError(error);
      });
  },
  methods: {
    title1 () {
      return this.type + ' by ' + this.groupBy;
    },
    title2 () {
      let title = this.type;
      if(this.groupBy == 'Head') {
        title += ' by Tag';
        if(this.apiParams2.filter.head)
          title += ', Head = ' + this.apiParams2.filter.head;
      } else if(this.groupBy == 'Tag') {
        title += ' by Head';
        if(this.apiParams2.filter.tag)
          title += ', Tag = ' + this.apiParams2.filter.tag;
      }
      return title;
    },
    titleAcc () {
      let title = this.type + ' by Account';
      if(this.groupBy == 'Head') {
        if(this.apiParamsAcc.filter.head)
          title += ', Head = ' + this.apiParamsAcc.filter.head;
        if(this.apiParamsAcc.filter.tag)
          title += ', Tag = ' + this.apiParamsAcc.filter.tag;
      } else if(this.groupBy == 'Tag') {
        if(this.apiParamsAcc.filter.tag)
          title += ', Tag = ' + this.apiParamsAcc.filter.tag;
        if(this.apiParamsAcc.filter.head)
          title += ', Head = ' + this.apiParamsAcc.filter.head;
      }
      return title;
    },
    titleTxn () {
      let title = 'Transactions';
      if(this.groupBy == 'Head') {
        if(this.apiParamsTxn.head)
          title += ', Head = ' + this.apiParamsTxn.head;
        if(this.apiParamsTxn.tag)
          title += ', Tag = ' + this.apiParamsTxn.tag;
        if(this.apiParamsTxn.account)
          title += ', Account = ' + this.apiParamsTxn.account;
      } else if(this.groupBy == 'Tag') {
        if(this.apiParamsTxn.tag)
          title += ', Tag = ' + this.apiParamsTxn.tag;
        if(this.apiParamsTxn.head)
          title += ', Head = ' + this.apiParamsTxn.head;
        if(this.apiParamsTxn.account)
          title += ', Account = ' + this.apiParamsTxn.account;
      }
      return title;
    },
    cssClassFn () {
      if(this.type == 'Expenses')
        return 'mt-2 red lighten-5'
      return 'mt-2'
    },
    rowClassFn (row) {
      return Object.values(row)[0] == 'Total' ? 'font-weight-bold' : '';
    },
    rowClickFn1 (val) {
      if(this.groupBy == 'Head') {
        this.apiParams2.filter.head = val[0].Head == 'Total' ? '' : val[0].Head;
        this.apiParams2.filter.tag  = '';
        this.apiParamsAcc.filter.head = val[0].Head == 'Total' ? '' : val[0].Head;
        this.apiParamsAcc.filter.tag  = '';
        this.apiParamsTxn.head    = val[0].Head == 'Total' ? '' : val[0].Head;
        this.apiParamsTxn.tag     = '';
        this.apiParamsTxn.account = '';
      } else if(this.groupBy == 'Tag') {
        this.apiParams2.filter.head = '';
        this.apiParams2.filter.tag  = val[0].Tag == 'Total' ? '' : val[0].Tag;
        this.apiParamsAcc.filter.head = '';
        this.apiParamsAcc.filter.tag  = val[0].Tag == 'Total' ? '' : val[0].Tag;
        this.apiParamsTxn.head    = '';
        this.apiParamsTxn.tag     = val[0].Tag == 'Total' ? '' : val[0].Tag;
        this.apiParamsTxn.account = '';
      }
    },
    rowClickFn2 (val) {
      if(this.groupBy == 'Head') {
        this.apiParamsAcc.filter.tag = val[0].Tag == 'Total' ? '' : val[0].Tag;
        this.apiParamsTxn.tag     = val[0].Tag == 'Total' ? '' : val[0].Tag;
        this.apiParamsTxn.account = '';
      } else if(this.groupBy == 'Tag') {
        this.apiParamsAcc.filter.head  = val[0].Head == 'Total' ? '' : val[0].Head;
        this.apiParamsTxn.head    = val[0].Head == 'Total' ? '' : val[0].Head;
        this.apiParamsTxn.account = '';
      }
    },
    rowClickFnAcc (val) {
      this.apiParamsTxn.account = val[0].Account == 'Total' ? '' : val[0].Account;
    },
  }
};
</script>
