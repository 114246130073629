<template>
  <v-card flat>
    <v-card-title class="pb-2" v-if="title">
      <span>{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-card flat class="mb-2" v-for="item in items" :key="item">
        <v-card-text>

          <div class='d-flex'>
            <b v-if="item.note">{{ item.note }}</b>
            <v-spacer></v-spacer>
            <a :href="'//github.com/Zero65Tech/paisa/tree/master/service/data/transactions' + item.file" target="_blank" style="text-decoration: none; color: rgba(0, 0, 0, 0.87)" >{{ item.file }}</a>
          </div>

          <div v-for="table in item.tables" :key="table" class="mt-2">
            <i>{{ table.name }}:</i>
            <v-data-table
              dense
              :headers="table.headers"
              :items="table.rows"
              :item-class="rowClassFn"
              disable-sort
              disable-pagination
              hide-default-footer
            ></v-data-table>
          </div>

        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>



<script>
import axios from "axios";
import util from "@/util.js";
export default {
  props: {
    title: String,
    api: String,
    params: Object,
    currencyFormat: String
  },
  data() {
    return {
      transactions: []
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    params: {
      handler() { this.fetchData() },
      deep: true
    },
  },
  computed: {
    headers () {
      return [
        { text:'Date',    value:'Date',    width:'125px' },
        { text:'Amount',  value:'Amount',  width:'125px', align:'end', type: 'currencyInr2d' },
        { text:'Account', value:'Account', width:'150px' },
        { text:'Head',    value:'Head',    width:'200px' },
        { text:'Tag',     value:'Tag',     width:'200px' },
        { text:'Source',  value:'Source',  width:'150px' },
        { text:'Note',    value:'Note'                   },
      ];
    },
    headers2 () {
      return [
        { text:'Date',    value:'Date',   width:'125px' },
        { text:'Amount',  value:'Amount', width:'125px', align:'end', type: 'currencyInr2d' },
        { text:'Source',  value:'Source', width:'150px' },
        { text:'Note',    value:'Note'                  },
      ];
    },
    headers3 () {
      return [
        { text:'Date',        value:'Date',      width:'125px' },
        { text:'Amount',      value:'Amount',    width:'125px', align:'end', type: 'currencyInr2d' },
        { text:'Source From', value:'Source To', width:'150px' },
        { text:'Source From', value:'Source To', width:'150px' },
        { text:'Note',        value:'Note'                     },
      ];
    },
    items () {
      let items = [];
      this.transactions.forEach(transaction => {
        let item = {
          note: transaction.note,
          file: transaction.file,
          tables: []
        }
        Object.keys(transaction).forEach(type => {
          if(type == 'note' || type == 'file')
            return;
          else if(type == 'credit' || type == 'debit' || type == 'income' || type == 'expense' || type == 'refund' || type == 'tax')
            item.tables.push({
              name: type,
              headers: this.headers,
              rows: util.arrayToVuetifyTableRows([transaction[type]], this.headers)
            });
          else if(type == 'credits' || type == 'debits' || type == 'incomes' || type == 'expenses' || type == 'refunds' || type == 'taxes')
            item.tables.push({
              name: type,
              headers: this.headers,
              rows: util.arrayToVuetifyTableRows(transaction[type], this.headers)
            });
          else if(type == 'payment' || type == 'receipt')
            item.tables.push({
              name: type,
              headers: this.headers2,
              rows: util.arrayToVuetifyTableRows([transaction[type]], this.headers2)
            });
          else if(type == 'payments' || type == 'receipts')
            item.tables.push({
              name: type,
              headers: this.headers2,
              rows: util.arrayToVuetifyTableRows(transaction[type], this.headers2)
            });
          else if(type == 'transfer')
            item.tables.push({
              name: type,
              headers: this.headers3,
              rows: util.arrayToVuetifyTableRows([transaction[type]], this.headers3)
            });
          else if(type == 'transfers')
            item.tables.push({
              name: type,
              headers: this.headers3,
              rows: util.arrayToVuetifyTableRows(transaction[type], this.headers3)
            });
          else
            alert('Unknown type: ' + type)
        });
        items.push(item);
      });
      return items;
    }
  },
  methods: {
    fetchData () {
      axios
        .get(this.api, { params: this.params })
        .then((response) => {
          console.log(response.data);
          this.transactions = response.data;
        })
        .catch((error) => {
          util.handleError(error);
        });
    },
    rowClassFn (row) {
      if((this.params.account && row.Account != this.params.account)
        || (this.params.head && row.Head != this.params.head)
        || (this.params.tag && row.Tag != this.params.tag))
        return 'grey--text';
      return '';
    },
  },
};
</script>
